.skills-list {
    display: grid;
    list-style: none;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    width: -webkit-fill-available;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1em;
    padding: 0;
}

.skills-list li {
    padding: 15px;
    background-color: #1e90ff0a;
    border-radius: 8px;
    color: whitesmoke;
    display: inline-grid;
    justify-content: center;
    margin: 0;
}

.skills ul {
    padding: 0;
}

.skills ul li {
    color: white;
    text-align: center;
    display: inline-grid;
    margin: 0;
}