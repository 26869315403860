
.skills-right {
    width: 40%;
    float: right;
}

@media all and (max-width: 600px) {
    .skills > div {
        flex-direction: column !important;
    }

    .skills-right {
        width: 100%;
    }
}
