header {
    width: 100%;
    background-color: rgb(40 44 52);
    display: inline-flex;
    justify-content: center;
}
header div {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

nav ul {
    list-style: none;
}

nav ul li a {
    transition-duration: .3s;
    color: dodgerblue;
    text-decoration: none;
}

nav ul li a:hover {
    color: rgba(30, 144, 255, 0.65);
}