
section {
    margin-bottom: 150px;
}

section > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
}

section ul {
    list-style: circle;
    margin: 20px;
    padding: 0;
}

section ul li {
    color: white;
}

section ul li.grid {
    text-align: center;
    gap: 1em;
    display: inline-grid;
}

section ul li img {
    margin: 0 auto auto auto;
}
