#cover {
    height: 100vh;
    width: 100vw;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 9999;
}

#cover div {
    display: flex;
    justify-content: space-between;
}

#cover ul {
    padding: 0;
    flex-direction: row;
    list-style: none;
}

.particles-background {
    background-color: #282c34;
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: .1;
    z-index: 1;
}


@media (max-width: 600px) {
    #cover div {
        flex-direction: column;
    }
}