.rounded-profile-photo {
    height: 200px;
    width: 200px;
    margin: 0 auto;
    border-radius: 100%;
    border: 5px solid rgba(30, 144, 255, .5);
    display: block;

    animation: flicker 2s infinite;
    -webkit-animation: flicker 2s infinite;
}

@keyframes flicker {
    0% {
        box-shadow: 10px 0 155px 5px dodgerblue;
    }
    20% {
        box-shadow: 12px 2px 145px 5px dodgerblue;
    }
    40% {
        box-shadow: 8px -2px 165px 5px dodgerblue;
    }
    60% {
        box-shadow: 10px 0 155px 5px dodgerblue;
    }
    80% {
        box-shadow: 12px 0 140px 5px dodgerblue;
    }
    100% {
        box-shadow: 10px 0 155px 5px dodgerblue;
    }
}

@-webkit-keyframes flicker {
    0% {
        -webkit-box-shadow: 10px 0 155px 5px dodgerblue;
    }
    20% {
        -webkit-box-shadow: 12px 2px 145px 5px dodgerblue;
    }
    40% {
        -webkit-box-shadow: 8px -2px 165px 5px dodgerblue;
    }
    60% {
        -webkit-box-shadow: 10px 0 155px 5px dodgerblue;
    }
    80% {
        -webkit-box-shadow: 12px 0 140px 5px dodgerblue;
    }
    100% {
        -webkit-box-shadow: 10px 0 155px 5px dodgerblue;
    }
}
