.project-thumb {
    border: 1px solid #1e90ff24;
    border-radius: 8px;
    padding-bottom: 10px;
}

.project-thumb:hover {
    transition-duration: .3s;
    background-color: #1e90ff24;
    box-shadow: 0px 0px 7px 1px rgba(30,144,255,0.5);
    -webkit-box-shadow: 0px 0px 7px 1px rgba(30,144,255,0.5);
    -moz-box-shadow: 0px 0px 7px 1px rgba(30,144,255,0.5);
}

.project-thumb img {
    height: 200px;
    max-width: 100%;
    padding-bottom: 5px;
}