#social-links ul {
    display: flex;

}

.social-links-vertical ul {
    flex-direction: column !important;
    justify-content: space-around;
}
.social-links-horizontal ul {
    flex-direction: row !important;
    justify-content: center;
}

#social-links ul li {
    transition-duration: .2s;
    margin: 30px;
    padding: 5px;
    border-radius: 5px;
    filter: invert(40%) sepia(5%) saturate(9%) hue-rotate(318deg) brightness(95%) contrast(79%); /* dimgray */
    display: block;
}

#social-links ul li:hover {
    transition-duration: .2s;
    border-radius: 5px;
    background-color: rgba(220, 220, 220, 0.01);
}

#social-links ul li:hover {
    transition-duration: .2s;
    filter: saturate(0%) brightness(200%);
}

#social-links ul li a span {
    margin-left: 10px;
}

@media all and (max-width: 600px) {
    #social-links ul {
        flex-direction: row !important;
    }
}