.tabs-wrapper {
    display: flex;
}

.tabs-headers {
    width: 20%;
}

.tab {
    padding: 15px;
    color:whitesmoke;
    border-left: 2px solid grey;
}

.tabs-content {
    width: 70%;
}

.active {
    transition-duration: .1s;
    font-weight: bolder;
    color: dodgerblue;
    border-color: dodgerblue;
}
