.hire-content  {
    width: 100%;
    background-color: dodgerblue;
    padding: 60px 0 60px 0;
    margin-bottom: 150px;
    text-align: center;
    color: #282c34 !important;
}

.hire-content h1, .hire-content p {
    color: #282c34;
}