body {
  padding: 0;
  margin: 0;
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  pointer-events: none;
}

.white {
  color: white;
}

h1, h2, h3, h4 {
  color: #eaeaea;
}

h1 {
  font-size: 25pt;
}

li a { margin: 0px; display: block; width: 100%; height: 100%; }

li {
  list-style-type: none;
}

ul .arrow-list :before {
  content: "▹ ";
  color: dodgerblue;
}

a {
  text-decoration: none;
  color: dodgerblue;
}

strong {
  color: dodgerblue;
}

p {
  color: #eaeaea;
}

.light-text {
  color: #eaeaea;
  font-weight: lighter;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #313741;
}

.scroll-to-top {
  right: 45px !important;
  bottom: 130px !important;
}



.carousel-animation {
  animation: 1.5s ease-out opacity infinite;
  animation-direction: alternate;
}

@media (max-width: 768px) {
  .floating-whatsapp-chatbox {
    top: 10px;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}