.personal-banner  {
    width: 100%;
    padding: 100px 0 100px 0;
    margin-bottom: 150px;
    text-align: center;
    color: white !important;
    background-image: url('./banner-web.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    border: 1px solid rgba(30, 144, 255, 0.37);
    border-left: 0;
    border-right: 0;
}

@media (max-width: 768px) {
    .personal-banner {
        background-attachment: scroll;
        background-size: contain;
        padding: 37px 0;
    }
}