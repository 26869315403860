.card {
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: rgba(185, 185, 185, .02);
    text-align: justify;
}

.card h2 {
    margin: 0;
}