.projects {
    display: flex;
    justify-content: space-around;
}

.projects {
    padding: 0;
    display: inline-grid;
    width: -webkit-fill-available;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1em;
    gap: 1em;
}

.projects {
    text-align: center;
    display: inline-grid;
    margin: 0;
}

@media all and (max-width: 600px) {
    .projects {
        flex-direction: column !important;
    }
}
